import React, { useState, useRef } from "react";
import "../../App.css";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import * as XLSX from "xlsx";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

import EXP_OFF_EMPTY from "../../exports/EMPTY_FILES/EXP_OFF_EMPTY";
import EXP_OFF_CONFRONTO from "../../exports/OFF/EXP_OFF_CONFRONTO";

import BeatLoader from "react-spinners/BeatLoader";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";

import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import OFFER_INSTR_POPUP from "../../popup/INSTRUCTION_POPUP/OFFER_INSTR_POPUP";
// import OFF_SECOND_CONFIRM_POPUP from "../../popup/OFFER_POPUP/OFF_SECOND_CONFIRM_POPUP";
import OFF_CHANGE_CONFIRM_POPUP from "../../popup/OFFER_POPUP/OFF_CHANGE_CONFIRM_POPUP";
import OFF_ERRORS_CONFIRM_POPUP from "../../popup/OFFER_POPUP/OFF_ERRORS_CONFIRM_POPUP";

import EXP_OFF_CHANGE_MATCH from "../../exports/OFF/EXP_OFF_CHANGE_MATCH";

import equivalenzeService from "../../services/equivalenze";

import {
  SINGLE_OFF_IMPORT_COLUMNS,
  SINGLE_OFF_CONFRONTO_COLUMNS,
  SINGLE_OFF_ERRORS_COLUMNS,
  SINGLE_OFF_CONFRONTO_PREZZI_COLUMNS,
} from "../../colums/SINGLE_OFF_COLUMNS";

const SingleOfferImportChange = (props) => {
  const {
    updateDataFileChecked,
    offerFormData,
    confirmChangeOFF,
    setConfirmChangeOFF,
    offersListDownloaded,
  } = props;

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  const fileRef = useRef();

  const [confirmedFile, setConfirmedFile] = useState(false);
  // console.log("confirmedFile", confirmedFile);

  const [dataFile, setDataFile] = useState(null);
  // console.log("dataFile", dataFile);

  const [importErrors, setImportErrors] = useState(null);

  const [notMatch, setNotMatch] = useState([]);
  const [noMatchFlag, setNoMatchFlag] = useState(false);

  const [fileValutation, setFileValutation] = useState([]);
  // console.log("fileValutation", fileValutation);

  const [priceCheckConfirmed, setPriceCheckConfirmed] = useState(false);

  const acceptableFileName = ["xlsx", "xls"];

  const [instructionOpen, setInstructionOpen] = useState(false);

  const [errorConfirm, setErrorConfirm] = useState(false);

  const [changeConfirmOpen, setChangeConfirmOpen] = useState(false);

  const [loadingFarmData, setLoadingFarmData] = useState(false);

  const [completezzaOFF_OBJ, setCompletezzaOFF_OBJ] = useState(null);

  const checkFileName = (name) => {
    return acceptableFileName.includes(name.split(".").pop().toLowerCase());
  };

  const readDataFromFile = (data) => {
    const wb = XLSX.read(data);

    var mySheetData = {};
    const worksheet = wb.Sheets[wb.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    mySheetData = jsonData;

    return mySheetData;
  };

  const findCommonElementsWithFlags = (data) => {
    const idMap = {};

    // Popoliamo la mappa degli id temporanei
    data.forEach((item) => {
      if (!idMap[item.id_temp]) {
        idMap[item.id_temp] = { ...item };
      } else {
        Object.keys(item).forEach((key) => {
          if (typeof item[key] === "boolean" && key.startsWith("flag_")) {
            idMap[item.id_temp][key] = idMap[item.id_temp][key] || item[key];
          }
        });
      }
    });

    // Converto l'oggetto mappa in un array di oggetti
    const combinedObjects = Object.values(idMap);

    return combinedObjects;
  };

  const handleUploadDataControl = async (e) => {
    let newFileData = [];

    let id_data_temp = 0;

    await e.map((Sel) => {
      let temp = {
        id_temp: id_data_temp,
        aic: Sel.AIC !== undefined ? Sel.AIC : "",
        nome_commerciale:
          Sel["NOME COMMERCIALE"] !== undefined ? Sel["NOME COMMERCIALE"] : "",
        principio_attivo:
          Sel["PRINCIPIO ATTIVO"] !== undefined ? Sel["PRINCIPIO ATTIVO"] : "",
        atc: Sel.ATC !== undefined ? Sel.ATC : "",
        atc_complementare:
          Sel["ATC COMP."] !== undefined ? Sel["ATC COMP."] : "",
        descrizione_atc:
          Sel["DESCRIZIONE ATC"] !== undefined ? Sel["DESCRIZIONE ATC"] : "",
        prezzo_unitario:
          Sel["PREZZO UNITARIO"] !== undefined ? Sel["PREZZO UNITARIO"] : "",
        prezzo_pub_confezione:
          Sel["PREZZO CONFEZIONE"] !== undefined
            ? Sel["PREZZO CONFEZIONE"]
            : "",
        numero_unita_confezione:
          Sel["NUMERO UNITA CONFEZIONE"] !== undefined
            ? Sel["NUMERO UNITA CONFEZIONE"]
            : "",
        prezzo_pub_unitario:
          Sel["PREZZO PUBB. UNITARIO"] !== undefined
            ? Sel["PREZZO PUBB. UNITARIO"]
            : "",
        // sconto_precentuale:
        //   Sel["SCONTO PERCENTUALE"] !== undefined
        //     ? Sel["SCONTO PERCENTUALE"]
        //     : "",
        // prezzo_netto_exfactory_confezione:
        //   Sel["PREZZO NETO EX FACTORY CONFEZIONE"] !== undefined
        //     ? Sel["PREZZO NETO EX FACTORY CONFEZIONE"]
        //     : "",
        // prezzo_netto_exfactory_unitario:
        //   Sel["PREZZO NETO EX FACTORY UNITARIO"] !== undefined
        //     ? Sel["PREZZO NETO EX FACTORY UNITARIO"]
        //     : "",
        // dosaggio: Sel.DOSAGGIO !== undefined ? Sel.DOSAGGIO : "",
        // fascia: Sel.FASCIA !== undefined ? Sel.FASCIA : "",
      };

      newFileData.push(temp);

      id_data_temp = id_data_temp + 1;

      return e;
    });

    let errors = {
      aic_error: [],
      name_error: [],
      prezzo_error: [],
      flag_error: false,
    };

    await newFileData.map((Sel) => {
      if (Sel.aic === "" || Sel.aic.toString().trim().length > 9) {
        errors.aic_error.push(Sel);
        errors.flag_error = true;
      } else if (Sel.nome_commerciale === "") {
        errors.name_error.push(Sel);
        errors.flag_error = true;
      } else if (Sel.prezzo_unitario === "") {
        errors.prezzo_error.push(Sel);
        errors.flag_error = true;
      }
      return newFileData;
    });

    if (!errors.flag_error) {
      await newFileData.map((Sel) => {
        if (Sel.aic.toString().length < 9) {
          let tempAIC = Sel.aic.toString();

          for (let i = Sel.aic.toString().trim().length; i < 9; i++) {
            tempAIC = "0" + tempAIC;
          }
          Sel.aic = tempAIC.trim();
        } else {
          Sel.aic = Sel.aic.toString();
        }

        return newFileData;
      });
      setDataFile(newFileData);
      setImportErrors(null);
    } else {
      let newErrorsTEMP = [];

      await errors.aic_error.map((ERR_SEL) => {
        let temp = {
          id_temp: ERR_SEL.id_temp,
          aic: ERR_SEL.aic,
          nome_commerciale: ERR_SEL.nome_commerciale,
          fornitore_ragioneSociale_FILE: ERR_SEL.fornitore_ragioneSociale_FILE,
          fornitore_pivaFILE: ERR_SEL.fornitore_pivaFILE,
          atc: ERR_SEL.atc,
          atc_complementare: ERR_SEL.atc_complementare,
          prezzo_unitario: ERR_SEL.prezzo_unitario,
          quantita: ERR_SEL.quantita,
          flag_aic_error: true,
          flag_name_error: false,
          flag_piva_error: false,
          flag_prezzo_error: false,
          flag_quantita_error: false,
        };

        newErrorsTEMP.push(temp);

        return errors.aic_error;
      });

      await errors.name_error.map((ERR_SEL) => {
        let temp = {
          id_temp: ERR_SEL.id_temp,
          aic: ERR_SEL.aic,
          nome_commerciale: ERR_SEL.nome_commerciale,
          fornitore_ragioneSociale_FILE: ERR_SEL.fornitore_ragioneSociale_FILE,
          fornitore_pivaFILE: ERR_SEL.fornitore_pivaFILE,
          atc: ERR_SEL.atc,
          atc_complementare: ERR_SEL.atc_complementare,
          prezzo_unitario: ERR_SEL.prezzo_unitario,
          quantita: ERR_SEL.quantita,
          flag_aic_error: false,
          flag_name_error: true,
          flag_piva_error: false,
          flag_prezzo_error: false,
          flag_quantita_error: false,
        };

        newErrorsTEMP.push(temp);
        return errors.name_error;
      });

      await errors.prezzo_error.map((ERR_SEL) => {
        let temp = {
          id_temp: ERR_SEL.id_temp,
          aic: ERR_SEL.aic,
          nome_commerciale: ERR_SEL.nome_commerciale,
          fornitore_ragioneSociale_FILE: ERR_SEL.fornitore_ragioneSociale_FILE,
          fornitore_pivaFILE: ERR_SEL.fornitore_pivaFILE,
          atc: ERR_SEL.atc,
          atc_complementare: ERR_SEL.atc_complementare,
          prezzo_unitario: ERR_SEL.prezzo_unitario,
          quantita: ERR_SEL.quantita,
          flag_aic_error: false,
          flag_name_error: false,
          flag_piva_error: false,
          flag_prezzo_error: true,
          flag_quantita_error: false,
        };

        newErrorsTEMP.push(temp);
        return errors.prezzo_error;
      });

      const commonElements = findCommonElementsWithFlags(newErrorsTEMP);
      setImportErrors(commonElements);

      await newFileData.map((Sel) => {
        if (Sel.aic.toString().length < 9) {
          let tempAIC = Sel.aic.toString();

          for (let i = Sel.aic.toString().trim().length; i < 9; i++) {
            tempAIC = "0" + tempAIC;
          }
          Sel.aic = tempAIC.trim();
        } else {
          Sel.aic = Sel.aic.toString();
        }

        return newFileData;
      });
      setDataFile(newFileData);
    }
  };

  const handleFile = async (e) => {
    setDataFile(null);
    const myfile = e.target.files[0];
    if (!myfile) return;

    if (!checkFileName(myfile.name)) {
      alert("Invalid file type!");
      return;
    }

    const data = await myfile.arrayBuffer();
    const mySheetData = readDataFromFile(data);

    readDataFromFile(data);

    handleUploadDataControl(mySheetData);
  };

  const handleRemove = () => {
    setDataFile(null);
    setConfirmedFile(false);
    setImportErrors(null);
    setPriceCheckConfirmed(false);
    setCompletezzaOFF_OBJ(null);
  };

  const handleNoMatchFlag = () => {
    setNoMatchFlag(!noMatchFlag);
  };

  const handleFirstConfirmWithErrors = async () => {
    setLoadingFarmData(true);

    let dataFileTEMP = [...dataFile];
    let foundedAIC = [];
    let matchFarmadati = [];
    // let noMatch = [];

    // console.log("dataFileTEMP", dataFileTEMP);
    // console.log("importErrors", importErrors);

    const idTempSet = new Set(importErrors.map((item) => item.id_temp));
    let dataFileTEMP_NOERR = dataFileTEMP.filter(
      (item) => !idTempSet.has(item.id_temp)
    );

    // console.log("dataFileTEMP_NOERR", dataFileTEMP_NOERR);

    let AICList = [];

    dataFileTEMP_NOERR.map((Selected) => {
      AICList.push(Selected.aic);
      return dataFileTEMP_NOERR;
    });

    equivalenzeService.getAIC(AICList).then((res) => {
      foundedAIC = res;

      let matchList = [];
      dataFileTEMP_NOERR.map((Selected) => {
        let tempFound = [];
        foundedAIC.map((element) => {
          if (element.data.length > 0 && element.data[0].aic === Selected.aic) {
            tempFound = element.data[0];
          } else {
          }
          return foundedAIC;
        });

        let matchListTemp = {
          inserted_excell: Selected,
          found_farmadati: tempFound,
        };

        matchList.push(matchListTemp);
        return dataFileTEMP_NOERR;
      });

      let id_off_temp = 0;

      matchList.map((Selected) => {
        let temp = [];
        if (
          Selected?.found_farmadati.length === 0 ||
          Selected?.inserted_excell.aic === undefined
        ) {
          temp = {
            id_offerta_temp: id_off_temp,
            aic: Selected?.inserted_excell.aic.toString(),
            nome_commerciale: Selected?.inserted_excell?.nome_commerciale,
            atc: Selected?.inserted_excell?.atc,
            atc_complementare: Selected?.inserted_excell?.atc_complementare,
            descrizione_atc: Selected?.inserted_excell?.descrizione_atc,
            prezzo_unitario:
              Math.round(Selected?.inserted_excell?.prezzo_unitario * 100000) /
              100000,

            aic_farmadati: "NO MATCH",
            nome_commerciale_farmadati: "NO MATCH",
            atc_farmadati: "NO MATCH",
            atc_complementare_farmadati: "NO MATCH",
            codice_gruppo_ospedaliero: "NO MATCH",
            descrizione_gruppo_ospedaliero: "NO MATCH",
            descrizione_atc_farmadati: "NO MATCH",
            principio_attivo_farmadati: "NO MATCH",
            forma_farmaceutica: "NO MATCH",
            numero_pezzi_farmadati: "NO MATCH",
            dosaggio: "NO MATCH",
            fascia: "NO MATCH",
            iva: 0,
            prezzo_pub_confezione:
              Selected?.inserted_excell?.prezzo_pub_confezione,
            numero_unita_confezione:
              Selected?.inserted_excell?.numero_unita_confezione,
            // sconto_precentuale_prezzo_pub:
            //   Selected?.inserted_excell?.sconto_precentuale,
            // prezzo_pub_unitario: Selected?.inserted_excell?.prezzo_pub_unitario,
            // prezzo_netto_exfactory_confezione:
            //   Selected?.inserted_excell?.prezzo_netto_exfactory_confezione,
            // prezzo_netto_exfactory_unitario:
            //   Selected?.inserted_excell?.prezzo_netto_exfactory_unitario,
          };
        } else {
          temp = {
            id_offerta_temp: id_off_temp,
            aic: Selected?.inserted_excell.aic.toString(),
            nome_commerciale: Selected?.inserted_excell?.nome_commerciale,
            atc: Selected?.inserted_excell?.atc,
            atc_complementare: Selected?.inserted_excell?.atc_complementare,
            descrizione_atc: Selected?.inserted_excell?.descrizione_atc,
            prezzo_unitario:
              Math.round(Selected?.inserted_excell?.prezzo_unitario * 100000) /
              100000,
            aic_farmadati: Selected?.found_farmadati.aic,
            nome_commerciale_farmadati:
              Selected?.found_farmadati.descrizione_aic,
            atc_farmadati: Selected?.found_farmadati.atc,
            atc_complementare_farmadati:
              Selected?.found_farmadati.atc_complementare,
            codice_gruppo_ospedaliero:
              Selected?.found_farmadati.codice_gruppo_ospedaliero,
            descrizione_gruppo_ospedaliero:
              Selected?.found_farmadati.descrizione_gruppo_ospedaliero,
            descrizione_atc_farmadati:
              Selected?.found_farmadati.descrizione_atc,
            principio_attivo_farmadati:
              Selected?.found_farmadati.principio_attivo,
            numero_pezzi_farmadati: Selected?.found_farmadati.numero_pezzi,
            forma_farmaceutica: Selected?.found_farmadati.forma_farmaceutica,
            dosaggio: Selected?.found_farmadati.dosaggio,
            fascia: Selected?.found_farmadati.fascia,
            iva: Selected?.found_farmadati.iva,
            prezzo_pub_confezione:
              Selected?.inserted_excell?.prezzo_pub_confezione,
            numero_unita_confezione:
              Selected?.inserted_excell?.numero_unita_confezione,
            // sconto_precentuale_prezzo_pub:
            //   Selected?.inserted_excell?.sconto_precentuale,
            // prezzo_pub_unitario: Selected?.inserted_excell?.prezzo_pub_unitario,
            // prezzo_netto_exfactory_confezione:
            //   Selected?.inserted_excell?.prezzo_netto_exfactory_confezione,
            // prezzo_netto_exfactory_unitario:
            //   Selected?.inserted_excell?.prezzo_netto_exfactory_unitario,
          };
        }
        matchFarmadati.push(temp);
        id_off_temp = id_off_temp + 1;

        return matchList;
      });

      let noMatch = [];
      let completeOBJ = [];

      matchFarmadati.map((Selected) => {
        if (Selected?.aic_farmadati === "NO MATCH") {
          noMatch.push(Selected);
        } else {
          completeOBJ.push(Selected);
        }

        return matchFarmadati;
      });

      const finalOBJ = noMatch.concat(completeOBJ);

      if (noMatch.length > 0) {
        setNoMatchFlag(true);
      }

      setFileValutation(finalOBJ);
      setNotMatch(noMatch);
      setConfirmedFile(true);
      setErrorConfirm(false);
      setImportErrors(null);
      setLoadingFarmData(false);
    });
  };

  const handleFirstConfirm = () => {
    setLoadingFarmData(true);

    let dataFileTEMP = [...dataFile];
    let foundedAIC = [];
    let matchFarmadati = [];
    let noMatch = [];

    let AICList = [];

    dataFileTEMP.map((Selected) => {
      AICList.push(Selected.aic);
      return dataFileTEMP;
    });

    equivalenzeService.getAIC(AICList).then((res) => {
      foundedAIC = res;

      let matchList = [];
      dataFileTEMP.map((Selected) => {
        let tempFound = [];
        foundedAIC.map((element) => {
          if (element.data.length > 0 && element.data[0].aic === Selected.aic) {
            tempFound = element.data[0];
          } else {
          }
          return foundedAIC;
        });

        let matchListTemp = {
          inserted_excell: Selected,
          found_farmadati: tempFound,
        };

        matchList.push(matchListTemp);
        return dataFileTEMP;
      });

      let id_off_temp = 0;

      matchList.map((Selected) => {
        let temp = [];
        if (
          Selected.found_farmadati.length === 0 ||
          Selected.inserted_excell.aic === undefined
        ) {
          temp = {
            id_offerta_temp: id_off_temp,
            aic: Selected.inserted_excell.aic.toString(),
            nome_commerciale: Selected.inserted_excell?.nome_commerciale,
            atc: Selected.inserted_excell?.atc,
            atc_complementare: Selected.inserted_excell?.atc_complementare,
            descrizione_atc: Selected.inserted_excell?.descrizione_atc,
            prezzo_unitario:
              Math.round(Selected.inserted_excell?.prezzo_unitario * 100000) /
              100000,

            aic_farmadati: "NO MATCH",
            nome_commerciale_farmadati: "NO MATCH",
            atc_farmadati: "NO MATCH",
            atc_complementare_farmadati: "NO MATCH",
            codice_gruppo_ospedaliero: "NO MATCH",
            descrizione_gruppo_ospedaliero: "NO MATCH",
            descrizione_atc_farmadati: "NO MATCH",
            principio_attivo_farmadati: "NO MATCH",
            numero_pezzi_farmadati: "NO MATCH",
            forma_farmaceutica: "NO MATCH",
            dosaggio: "NO MATCH",
            fascia: "NO MATCH",
            iva: 0,
            prezzo_pub_confezione:
              Selected.inserted_excell?.prezzo_pub_confezione,
            numero_unita_confezione:
              Selected.inserted_excell?.numero_unita_confezione,
            // sconto_precentuale_prezzo_pub:
            //   Selected.inserted_excell?.sconto_precentuale,
            // prezzo_pub_unitario: Selected.inserted_excell?.prezzo_pub_unitario,
            // prezzo_netto_exfactory_confezione:
            //   Selected.inserted_excell?.prezzo_netto_exfactory_confezione,
            // prezzo_netto_exfactory_unitario:
            //   Selected.inserted_excell?.prezzo_netto_exfactory_unitario,
          };
        } else {
          temp = {
            id_offerta_temp: id_off_temp,
            aic: Selected.inserted_excell.aic.toString(),
            nome_commerciale: Selected.inserted_excell?.nome_commerciale,
            atc: Selected.inserted_excell?.atc,
            atc_complementare: Selected.inserted_excell?.atc_complementare,
            descrizione_atc: Selected.inserted_excell?.descrizione_atc,
            prezzo_unitario:
              Math.round(Selected.inserted_excell?.prezzo_unitario * 100000) /
              100000,
            aic_farmadati: Selected.found_farmadati.aic,
            nome_commerciale_farmadati:
              Selected.found_farmadati.descrizione_aic,
            atc_farmadati: Selected.found_farmadati.atc,
            atc_complementare_farmadati:
              Selected.found_farmadati.atc_complementare,
            codice_gruppo_ospedaliero:
              Selected.found_farmadati.codice_gruppo_ospedaliero,
            descrizione_gruppo_ospedaliero:
              Selected.found_farmadati.descrizione_gruppo_ospedaliero,
            descrizione_atc_farmadati: Selected.found_farmadati.descrizione_atc,
            principio_attivo_farmadati:
              Selected.found_farmadati.principio_attivo,
            numero_pezzi_farmadati: Selected?.found_farmadati.numero_pezzi,
            forma_farmaceutica: Selected.found_farmadati.forma_farmaceutica,
            dosaggio: Selected.found_farmadati.dosaggio,
            fascia: Selected.found_farmadati.fascia,
            iva: Selected.found_farmadati.iva,
            prezzo_pub_confezione:
              Selected.inserted_excell?.prezzo_pub_confezione,
            numero_unita_confezione:
              Selected.inserted_excell?.numero_unita_confezione,
            // sconto_precentuale_prezzo_pub:
            //   Selected.inserted_excell?.sconto_precentuale,
            // prezzo_pub_unitario: Selected.inserted_excell?.prezzo_pub_unitario,
            // prezzo_netto_exfactory_confezione:
            //   Selected.inserted_excell?.prezzo_netto_exfactory_confezione,
            // prezzo_netto_exfactory_unitario:
            //   Selected.inserted_excell?.prezzo_netto_exfactory_unitario,
          };
        }
        matchFarmadati.push(temp);
        id_off_temp = id_off_temp + 1;

        return matchList;
      });

      matchFarmadati.map((Selected) => {
        if (Selected.aic_farmadati === "NO MATCH") {
          noMatch.push(Selected);
        }

        return matchFarmadati;
      });

      setFileValutation(matchFarmadati);
      setNotMatch(noMatch);
      setConfirmedFile(true);
      setLoadingFarmData(false);
    });
  };

  const handleChangeOfferConfirm = () => {
    let finalFile = [];

    completezzaOFF_OBJ.TROVATI.map((Selected) => {
      if (Selected.aic_farmadati !== "NO MATCH") {
        let temp = {
          id_offerta_temp: Selected.id_offerta_temp.toString(),
          numero_protocollo: offerFormData.protocolNumber,
          data_protocollo: offerFormData.protocolDate,
          aic: Selected.aic,
          nome_commerciale: Selected.nome_commerciale_farmadati,
          atc: Selected.atc_farmadati || "",
          atc_complementare: Selected.atc_complementare_farmadati || "",
          codice_gruppo_ospedaliero: Selected.codice_gruppo_ospedaliero || "",
          descrizione_gruppo_ospedaliero:
            Selected.descrizione_gruppo_ospedaliero || "",
          descrizione_atc: Selected.descrizione_atc_farmadati || "",
          prezzo_unitario: Selected.prezzo_unitario,
          principio_attivo: Selected.principio_attivo_farmadati || "",
          forma_farmaceutica: Selected.forma_farmaceutica || "",
          dosaggio: Selected.dosaggio || "",
          fascia: Selected.fascia || "",
          iva: Selected.iva || 0,
          prezzo_pub_confezione: Selected.prezzo_pub_confezione || "",
          numero_unita_confezione: Selected.numero_unita_confezione || "",
          // sconto_precentuale: Selected.sconto_precentuale || "",
          // prezzo_pub_unitario: Selected.prezzo_pub_unitario || "",
          // prezzo_netto_exfactory_confezione:
          //   Selected.prezzo_netto_exfactory_confezione || "",
          // prezzo_netto_exfactory_unitario:
          //   Selected.prezzo_netto_exfactory_unitario || "",
          filef_flag: false,
          is_checked: false,
        };
        finalFile.push(temp);
      }

      return completezzaOFF_OBJ.TROVATI;
    });

    completezzaOFF_OBJ.NUOVI.map((Selected) => {
      if (Selected.aic_farmadati !== "NO MATCH") {
        let temp = {
          id_offerta_temp: Selected.id_offerta_temp.toString(),
          numero_protocollo: offerFormData.protocolNumber,
          data_protocollo: offerFormData.protocolDate,
          aic: Selected.aic,
          nome_commerciale: Selected.nome_commerciale_farmadati,
          atc: Selected.atc_farmadati || "",
          atc_complementare: Selected.atc_complementare_farmadati || "",
          codice_gruppo_ospedaliero: Selected.codice_gruppo_ospedaliero || "",
          descrizione_gruppo_ospedaliero:
            Selected.descrizione_gruppo_ospedaliero || "",
          descrizione_atc: Selected.descrizione_atc_farmadati || "",
          prezzo_unitario: Selected.prezzo_unitario,
          principio_attivo: Selected.principio_attivo_farmadati || "",
          forma_farmaceutica: Selected.forma_farmaceutica || "",
          dosaggio: Selected.dosaggio || "",
          fascia: Selected.fascia || "",
          iva: Selected.iva || 0,
          prezzo_pub_confezione: Selected.prezzo_pub_confezione || "",
          numero_unita_confezione: Selected.numero_unita_confezione || "",
          sconto_precentuale: Selected.sconto_precentuale || "",
          prezzo_pub_unitario: Selected.prezzo_pub_unitario || "",
          prezzo_netto_exfactory_confezione:
            Selected.prezzo_netto_exfactory_confezione || "",
          prezzo_netto_exfactory_unitario:
            Selected.prezzo_netto_exfactory_unitario || "",
          filef_flag: false,
          is_checked: false,
        };
        finalFile.push(temp);
      }

      return completezzaOFF_OBJ.NUOVI;
    });

    completezzaOFF_OBJ.NON_TROVATI.map((Selected) => {
      if (Selected.aic_farmadati !== "NO MATCH") {
        let temp = {
          id_offerta_temp: Selected._id,
          numero_protocollo: Selected.numero_protocollo,
          data_protocollo: Selected.data_protocollo,
          aic: Selected.aic,
          nome_commerciale: Selected.nome_commerciale,
          atc: Selected.atc || "",
          atc_complementare: Selected.atc_complementare || "",
          codice_gruppo_ospedaliero: Selected.codice_gruppo_ospedaliero || "",
          descrizione_gruppo_ospedaliero:
            Selected.descrizione_gruppo_ospedaliero || "",
          descrizione_atc: Selected.descrizione_atc || "",
          prezzo_unitario: Selected.prezzo_unitario,
          principio_attivo: Selected.principio_attivo || "",
          forma_farmaceutica: Selected.forma_farmaceutica || "",
          dosaggio: Selected.dosaggio || "",
          fascia: Selected.fascia || "",
          iva: Selected.iva || 0,
          prezzo_pub_confezione: Selected.prezzo_pub_confezione || "",
          numero_unita_confezione: Selected.numero_unita_confezione || "",
          sconto_precentuale: Selected.sconto_precentuale || "",
          prezzo_pub_unitario: Selected.prezzo_pub_unitario || "",
          prezzo_netto_exfactory_confezione:
            Selected.prezzo_netto_exfactory_confezione || "",
          prezzo_netto_exfactory_unitario:
            Selected.prezzo_netto_exfactory_unitario || "",
          filef_flag: false,
          is_checked: false,
        };
        finalFile.push(temp);
      }

      return completezzaOFF_OBJ.NON_TROVATI;
    });

    console.log("finalFile", finalFile);

    updateDataFileChecked(finalFile);
  };

  const handleMatchOffers = () => {
    let OFF_LIST_TEMP = [...offersListDownloaded.offerte_inserite];
    let NEW_OFFER_TEMP = [...fileValutation];

    let OLD_OFFER_TEMP = {};

    OFF_LIST_TEMP.map((Selected) => {
      if (Selected.ragione_sociale === offerFormData.supplier.ragione_sociale) {
        OLD_OFFER_TEMP = Selected;
      } else {
        // console.log("ERROR! NO SUPPLIER MATCHED!!");
      }
      return OFF_LIST_TEMP;
    });

    let TROVATI = [];
    let NON_TROVATI = [];
    let NUOVI = [];

    NEW_OFFER_TEMP.map((Selected) => {
      OLD_OFFER_TEMP.offerta_obj.map((SelectedOFF) => {
        if (Selected.aic === SelectedOFF.aic) {
          Selected.prezzo_unitario_OLD = SelectedOFF.prezzo_unitario;
          TROVATI.push(Selected);
        }

        return OLD_OFFER_TEMP.offerta_obj;
      });

      return NEW_OFFER_TEMP;
    });

    OLD_OFFER_TEMP.offerta_obj.map((Selected) => {
      let nonTrovatoFlag = true;
      TROVATI.map((SelectedOFFTROVATI) => {
        if (SelectedOFFTROVATI.aic === Selected.aic) {
          nonTrovatoFlag = false;
        }
        return TROVATI;
      });

      if (nonTrovatoFlag) {
        NON_TROVATI.push(Selected);
      }
      return OLD_OFFER_TEMP.offerta_obj;
    });

    NEW_OFFER_TEMP.map((SelectedOFF) => {
      // console.log("SelectedOFF", SelectedOFF);
      let nuovoFlag = true;
      TROVATI.map((SelectedOFFTROVATI) => {
        if (SelectedOFFTROVATI.aic === SelectedOFF.aic) {
          nuovoFlag = false;
        }
        return TROVATI;
      });
      if (nuovoFlag) {
        NUOVI.push(SelectedOFF);
      }
      return NEW_OFFER_TEMP;
    });

    setCompletezzaOFF_OBJ({
      TROVATI: TROVATI,
      NON_TROVATI: NON_TROVATI,
      NUOVI: NUOVI,
    });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="singleOfferPage">
          {!confirmChangeOFF ? (
            <>
              <h2
                style={{
                  color: "#1976d2",
                  marginTop: "10%",
                }}
              >
                ESISTE GIA' UN'OFFERTA IN VIGORE DEL FORNITORE SELEZIONATO,{" "}
                <br /> VUOI PROCEDERE E CONFRONTARLE?
              </h2>

              <div
                style={{
                  textAlign: "center",
                  paddingTop: "2%",
                }}
              >
                <Button
                  className="Button"
                  variant="contained"
                  component="label"
                  color="success"
                  startIcon={<CheckOutlinedIcon />}
                  onClick={() => setConfirmChangeOFF(true)}
                >
                  CONFERMA
                </Button>
              </div>
            </>
          ) : (
            <>
              {!confirmedFile &&
                importErrors === null &&
                completezzaOFF_OBJ === null && (
                  <>
                    <div
                      style={{
                        textAlign: "left",
                        display: "inline-block",
                        width: "50%",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="bluetest"
                        onClick={() => setInstructionOpen(true)}
                        startIcon={<InfoOutlinedIcon />}
                      >
                        ISTRUZIONI D'INSERIMENTO
                      </Button>
                    </div>
                    <div
                      style={{
                        textAlign: "right",
                        display: "inline-block",
                        width: "30%",
                      }}
                    >
                      <EXP_OFF_EMPTY fornitore={offerFormData.supplier} />
                    </div>
                    <div
                      style={{
                        textAlign: "right",
                        display: "inline-block",
                        width: "20%",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="bluetest"
                        component="label"
                        startIcon={<BackupOutlinedIcon />}
                      >
                        UPLOAD FILE COMPILATO
                        <input
                          type="file"
                          hidden
                          accept="xlsx, xls"
                          multiple={false}
                          onChange={(e) => handleFile(e)}
                          ref={fileRef}
                        />
                      </Button>
                    </div>
                    {dataFile && !confirmedFile && (
                      <>
                        <Box
                          sx={{
                            height: "370px",
                            width: "100%",
                            paddingLeft: "10%",
                            paddingRight: "10%",
                            paddingTop: "2%",

                            "& .theme_header": {
                              backgroundColor: "#1976d2",
                              color: "#fff",
                              fontFamily: "Calibri",
                              fontSize: "16px",
                            },
                            "& .theme_cell": {
                              fontFamily: "Calibri",
                              fontSize: "17px",
                              border: "1px solid #000",
                            },
                          }}
                        >
                          <div
                            style={{
                              textAlign: "right",
                              display: "inline-block",
                              width: "100%",
                            }}
                          >
                            <h3
                              style={{
                                textAlign: "right",
                                fontFamily: "Calibri",
                                color: "#1976d2",
                                paddingBottom: "1%",
                              }}
                            >
                              TOT. ELEMENTI IMPORTATI: {dataFile.length}
                            </h3>
                          </div>

                          <DataGridPro
                            columns={SINGLE_OFF_IMPORT_COLUMNS}
                            rows={dataFile}
                            getRowId={(row) => row.id_temp}
                            headerHeight={40}
                            rowHeight={33}
                            disableColumnMenu
                            hideFooter
                          />
                        </Box>
                        {loadingFarmData ? (
                          <div style={{ paddingTop: "3%" }}>
                            <BeatLoader
                              color={"#1976d2"}
                              loading={loadingFarmData}
                              size={30}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                            />
                            <h6
                              style={{
                                fontFamily: "Calibri",
                                color: "#1976d2",
                              }}
                            >
                              CARICAMENTO...
                            </h6>
                          </div>
                        ) : (
                          <>
                            <div
                              style={{
                                textAlign: "center",
                                paddingTop: "3%",
                                paddingRight: "5%",
                                display: "inline-block",
                                width: "100%",
                              }}
                            >
                              <Button
                                className="Button"
                                variant="contained"
                                component="label"
                                color="success"
                                startIcon={<ManageSearchOutlinedIcon />}
                                onClick={handleFirstConfirm}
                              >
                                CONFRONTA CON FARMADATI
                              </Button>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

              {!confirmedFile &&
                importErrors !== null &&
                completezzaOFF_OBJ === null && (
                  <>
                    <div
                      style={{
                        textAlign: "left",
                        display: "inline-block",
                        width: "100%",
                      }}
                    >
                      <Button
                        className="Button"
                        variant="contained"
                        component="label"
                        color="redtest"
                        startIcon={<DeleteOutlinedIcon />}
                        onClick={handleRemove}
                      >
                        RIMUOVI IL FILE
                      </Button>
                    </div>
                    <h2
                      style={{
                        textAlign: "center",
                        color: "#f00",
                        fontFamily: "Calibri",
                        paddingTop: "1%",
                      }}
                    >
                      INDIVIDUATI ERRORI NEL FILE INSERITO:
                    </h2>
                    <div>
                      <Box
                        sx={{
                          height: "500px",
                          width: "100%",
                          paddingLeft: "5%",
                          paddingRight: "5%",
                          paddingTop: "1%",
                          "& .theme_header": {
                            backgroundColor: "#1976d2",
                            color: "#fff",
                            fontFamily: "Calibri",
                            fontSize: "17px",
                          },
                          "& .theme_cell": {
                            fontFamily: "Calibri",
                            fontSize: "18px",
                            border: "1px solid",
                          },
                          "& .theme_cell_error": {
                            fontFamily: "Calibri",
                            backgroundColor: "#f00",
                            fontSize: "18px",
                            fontWeight: "bold",
                            color: "#fff",
                            border: "1px solid #000",
                          },
                        }}
                      >
                        <DataGridPro
                          columns={SINGLE_OFF_ERRORS_COLUMNS}
                          rows={importErrors}
                          getRowId={(row) => row.id_temp}
                          headerHeight={40}
                          rowHeight={33}
                          disableColumnMenu
                          hideFooter
                        />
                      </Box>
                      <div
                        style={{
                          textAlign: "right",
                          display: "inline-block",
                          width: "100%",
                          marginTop: "1%",
                          paddingRight: "5%",
                        }}
                      >
                        <Button
                          className="Button"
                          variant="contained"
                          component="label"
                          color="redtest"
                          // startIcon={<DeleteOutlinedIcon />}
                          onClick={() => setErrorConfirm(true)}
                        >
                          PROSEGUI CON IL FILE IMPORTATO
                        </Button>
                      </div>
                    </div>
                  </>
                )}

              {confirmedFile &&
                importErrors === null &&
                !priceCheckConfirmed &&
                completezzaOFF_OBJ === null && (
                  <>
                    <Box
                      sx={{
                        height: "418px",
                        width: "100%",
                        paddingLeft: "1%",
                        paddingRight: "1%",
                        paddingTop: "1%",
                        "& .theme_header": {
                          backgroundColor: "#1976d2",
                          color: "#fff",
                          fontFamily: "Calibri",
                          fontSize: "17px",
                        },
                        "& .theme_cell": {
                          fontFamily: "Calibri",
                          fontSize: "18px",
                          border: "1px solid",
                        },
                        "& .theme_cell_nomatch": {
                          fontFamily: "Calibri",
                          fontSize: "18px",
                          color: "#ff1a00",
                          border: "1px solid",
                        },
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          width: "25%",
                          textAlign: "left",
                        }}
                      >
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value="bottom"
                              control={
                                <Switch
                                  checked={noMatchFlag}
                                  onChange={handleNoMatchFlag}
                                />
                              }
                              label={
                                <h4
                                  style={{
                                    fontFamily: "Calibri",
                                    color: noMatchFlag ? "#1976d2" : "#ccc",
                                  }}
                                >
                                  FILTRO NO MATCH
                                </h4>
                              }
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          display: "inline-block",
                          paddingRight: "3%",
                          width: "25%",
                        }}
                      >
                        <EXP_OFF_CONFRONTO
                          data={fileValutation}
                          fornitore={offerFormData.supplier}
                        />
                      </div>

                      {!noMatchFlag && (
                        <>
                          <div
                            style={{
                              textAlign: "right",
                              display: "inline-block",
                              width: "50%",
                            }}
                          >
                            <h3
                              style={{
                                textAlign: "right",
                                fontFamily: "Calibri",
                                color: "#1976d2",
                              }}
                            >
                              TOT ELEMENTI: {fileValutation.length}
                            </h3>
                          </div>
                          <DataGridPro
                            columns={SINGLE_OFF_CONFRONTO_COLUMNS}
                            rows={fileValutation}
                            getRowId={(row) => row.id_offerta_temp}
                            headerHeight={40}
                            rowHeight={33}
                            disableColumnMenu
                            hideFooter
                          />
                        </>
                      )}

                      {noMatchFlag && (
                        <>
                          <div
                            style={{
                              textAlign: "right",
                              display: "inline-block",
                              width: "50%",
                            }}
                          >
                            <h3
                              style={{
                                textAlign: "right",
                                fontFamily: "Calibri",
                                color: "#1976d2",
                              }}
                            >
                              TOT ELEMENTI: {notMatch.length}
                            </h3>
                          </div>

                          <DataGridPro
                            columns={SINGLE_OFF_CONFRONTO_COLUMNS}
                            rows={notMatch}
                            getRowId={(row) => row.id_offerta_temp}
                            headerHeight={40}
                            rowHeight={33}
                            disableColumnMenu
                            hideFooter
                          />
                        </>
                      )}
                    </Box>

                    <div
                      style={{
                        marginTop: "2%",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          width: "64%",
                        }}
                      ></div>
                      {/* <div
                        style={{
                          textAlign: "right",
                          paddingTop: "3%",
                          display: "inline-block",
                          width: "18%",
                        }}
                      >
                        <Button
                          className="Button"
                          variant="contained"
                          component="label"
                          color="success"
                          startIcon={<CheckOutlinedIcon />}
                          onClick={handleMatchOffers}
                        >
                          CONFERMA DATI
                        </Button>
                      </div> */}
                      <div
                        style={{
                          textAlign: "right",
                          paddingTop: "3%",
                          display: "inline-block",
                          width: "18%",
                        }}
                      >
                        <Button
                          className="Button"
                          variant="contained"
                          component="label"
                          color="success"
                          startIcon={<CheckOutlinedIcon />}
                          onClick={() => setPriceCheckConfirmed(true)}
                        >
                          CONTROLLA PREZZO
                        </Button>
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          paddingTop: "3%",
                          paddingRight: "3%",
                          display: "inline-block",
                          width: "18%",
                        }}
                      >
                        <Button
                          className="Button"
                          variant="contained"
                          component="label"
                          color="redtest"
                          startIcon={<DeleteOutlinedIcon />}
                          onClick={handleRemove}
                        >
                          RIMUOVI IL FILE
                        </Button>
                      </div>
                    </div>
                  </>
                )}

              {confirmedFile &&
                importErrors === null &&
                priceCheckConfirmed &&
                completezzaOFF_OBJ === null && (
                  <>
                    <Box
                      sx={{
                        height: "418px",
                        width: "100%",
                        paddingLeft: "1%",
                        paddingRight: "1%",
                        paddingTop: "1%",
                        "& .theme_header": {
                          backgroundColor: "#1976d2",
                          color: "#fff",
                          fontFamily: "Calibri",
                          fontSize: "17px",
                        },
                        "& .theme_cell": {
                          fontFamily: "Calibri",
                          fontSize: "18px",
                          border: "1px solid",
                        },
                        "& .theme_cell_nomatch": {
                          fontFamily: "Calibri",
                          fontSize: "18px",
                          color: "#ff1a00",
                          border: "1px solid",
                        },
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          width: "25%",
                          textAlign: "left",
                        }}
                      >
                        <FormControl component="fieldset">
                          <FormGroup aria-label="position" row>
                            <FormControlLabel
                              value="bottom"
                              control={
                                <Switch
                                  checked={noMatchFlag}
                                  onChange={handleNoMatchFlag}
                                />
                              }
                              label={
                                <h4
                                  style={{
                                    fontFamily: "Calibri",
                                    color: noMatchFlag ? "#1976d2" : "#ccc",
                                  }}
                                >
                                  FILTRO NO MATCH
                                </h4>
                              }
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          display: "inline-block",
                          paddingRight: "3%",
                          width: "25%",
                        }}
                      >
                        <EXP_OFF_CONFRONTO
                          data={fileValutation}
                          fornitore={offerFormData.supplier}
                        />
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          display: "inline-block",
                          width: "50%",
                        }}
                      >
                        <h3
                          style={{
                            textAlign: "right",
                            fontFamily: "Calibri",
                            color: "#1976d2",
                          }}
                        >
                          TOT ELEMENTI: {fileValutation.length}
                        </h3>
                      </div>
                      <DataGridPro
                        columns={SINGLE_OFF_CONFRONTO_PREZZI_COLUMNS}
                        rows={fileValutation}
                        getRowId={(row) => row.id_offerta_temp}
                        headerHeight={40}
                        rowHeight={33}
                        disableColumnMenu
                        hideFooter
                      />
                    </Box>

                    <div
                      style={{
                        marginTop: "2%",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          width: "64%",
                        }}
                      ></div>
                      <div
                        style={{
                          textAlign: "right",
                          paddingTop: "3%",
                          display: "inline-block",
                          width: "18%",
                        }}
                      >
                        <Button
                          className="Button"
                          variant="contained"
                          component="label"
                          color="success"
                          startIcon={<CheckOutlinedIcon />}
                          onClick={handleMatchOffers}
                        >
                          CONFERMA DATI
                        </Button>
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          paddingTop: "3%",
                          paddingRight: "3%",
                          display: "inline-block",
                          width: "18%",
                        }}
                      >
                        <Button
                          className="Button"
                          variant="contained"
                          component="label"
                          color="redtest"
                          startIcon={<DeleteOutlinedIcon />}
                          onClick={handleRemove}
                        >
                          RIMUOVI IL FILE
                        </Button>
                      </div>
                    </div>
                  </>
                )}

              {confirmedFile &&
                importErrors === null &&
                completezzaOFF_OBJ !== null && (
                  <>
                    <h2
                      style={{
                        color: "#1976d2",
                        marginTop: "10%",
                      }}
                    >
                      OFFERTE CONFRONTATE
                    </h2>
                    <EXP_OFF_CHANGE_MATCH
                      data={completezzaOFF_OBJ}
                      offerFormData={offerFormData}
                    />
                    <h3
                      style={{
                        color: "#1976d2",
                        marginTop: "10%",
                      }}
                    >
                      SE APPROVI LE MODIFICHE, PROSEGUI PER ESPORTARE LA NUOVA
                      OFFERTA ELABORATA
                    </h3>
                    <div
                      style={{
                        textAlign: "CENTER",
                        paddingTop: "3%",
                        // display: "inline-block",
                        // width: "18%",
                      }}
                    >
                      <Button
                        className="Button"
                        variant="contained"
                        component="label"
                        color="success"
                        startIcon={<CheckOutlinedIcon />}
                        onClick={() => setChangeConfirmOpen(true)}
                      >
                        CONFERMA MODIFICHE
                      </Button>
                    </div>
                  </>
                )}
            </>
          )}

          <OFFER_INSTR_POPUP
            instructionOpen={instructionOpen}
            setInstructionOpen={setInstructionOpen}
          />

          <OFF_ERRORS_CONFIRM_POPUP
            errorConfirm={errorConfirm}
            setErrorConfirm={setErrorConfirm}
            handleFirstConfirmWithErrors={handleFirstConfirmWithErrors}
            loadingFarmData={loadingFarmData}
          />

          <OFF_CHANGE_CONFIRM_POPUP
            changeConfirmOpen={changeConfirmOpen}
            setChangeConfirmOpen={setChangeConfirmOpen}
            handleChangeOfferConfirm={handleChangeOfferConfirm}
          />
        </div>
      </ThemeProvider>
    </>
  );
};

export default SingleOfferImportChange;
