//FUNZIONI
const RapprPrezzo = (PrezzoDaRappresentare) => {
  if (PrezzoDaRappresentare === "") {
    return PrezzoDaRappresentare;
  } else {
    let IntDec = PrezzoDaRappresentare.toString();
    IntDec = IntDec.split(".");

    let nuovoVal = "";
    let vecchioVal = IntDec[0];

    while (vecchioVal?.length > 3) {
      nuovoVal = "." + vecchioVal.substr(vecchioVal?.length - 3) + nuovoVal;
      vecchioVal = vecchioVal.substr(0, vecchioVal?.length - 3);
    }
    if (IntDec[1] !== undefined) {
      if (IntDec[1].length === 1) {
        nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1] + "0";
      } else {
        nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1];
      }
    } else {
      nuovoVal = vecchioVal + nuovoVal + ",00";
    }

    let finalVal = nuovoVal;

    if (finalVal.startsWith("-.")) {
      finalVal = "- " + finalVal.substr(2);
    } else if (finalVal.startsWith(".")) {
      finalVal = finalVal.substr(1);
    }

    return finalVal + " €";
  }
};

//COLUMNS
//SingleOfferImport
const SINGLE_OFF_ERRORS_COLUMNS = [
  {
    field: "id_temp",
    headerName: "RIGA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    flex: 0.3,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.id_temp + 2}</div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) => {
      if (params.row?.flag_aic_error) {
        return "theme_cell_error";
      } else {
        return "theme_cell";
      }
    },
    align: "center",
    flex: 0.6,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.aic}</div>
    ),
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) => {
      if (params.row?.flag_name_error) {
        return "theme_cell_error";
      } else {
        return "theme_cell";
      }
    },
    flex: 1,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.nome_commerciale}</div>
    ),
  },
  {
    field: "prezzo_unitario",
    headerName: "PREZZO UNITARIO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) => {
      if (params.row?.flag_prezzo_error) {
        return "theme_cell_error";
      } else {
        return "theme_cell";
      }
    },
    align: "right",
    flex: 0.5,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>
        {RapprPrezzo(params.row?.prezzo_unitario)}
      </div>
    ),
  },
];

//SingleOfferImport
const SINGLE_OFF_IMPORT_COLUMNS = [
  {
    field: "aic",
    headerName: "AIC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    sortable: false,
    flex: 0.5,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>{params.row?.aic}</div>
    ),
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>{params.row?.nome_commerciale}</div>
    ),
  },
  {
    field: "principio_attivo",
    headerName: "PRINICPIO ATTIVO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>{params.row?.principio_attivo}</div>
    ),
  },
  {
    field: "atc",
    headerName: "ATC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    sortable: false,
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>{params.row?.atc}</div>
    ),
  },
  {
    field: "atc_complementare",
    headerName: "ATC COMP.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    sortable: false,
    flex: 0.5,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>{params.row?.atc_complementare}</div>
    ),
  },
  {
    field: "prezzo_unitario",
    headerName: "PREZZO UNITARIO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    sortable: false,
    flex: 0.8,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>
        {parseFloat(params.row?.prezzo_unitario)
          ? parseFloat(params.row?.prezzo_unitario).toFixed(5)
          : "0.00000"}{" "}
        €
      </div>
    ),
  },
  {
    field: "prezzo_pub_confezione",
    headerName: "PREZZO CONFEZIONE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    sortable: false,
    flex: 0.8,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>
        {parseFloat(params.row?.prezzo_pub_confezione)
          ? parseFloat(params.row?.prezzo_pub_confezione).toFixed(2)
          : "X"}{" "}
        €
      </div>
    ),
  },
  {
    field: "numero_unita_confezione",
    headerName: "NUMERO PEZZI",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    sortable: false,
    flex: 0.8,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>
        {parseFloat(params.row?.numero_unita_confezione)
          ? parseFloat(params.row?.numero_unita_confezione).toFixed(0)
          : "X"}{" "}
      </div>
    ),
  },
];

//SingleOfferImport
const SINGLE_OFF_CONFRONTO_COLUMNS = [
  {
    field: "aic",
    headerName: "AIC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "center",
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.aic}</div>
    ),
    flex: 0.5,
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMM.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "left",
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.nome_commerciale}</div>
    ),
    flex: 1.2,
  },
  {
    field: "nome_commerciale_farmadati",
    headerName: "NOME FARM.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "left",
    flex: 1.2,
    renderCell: (params) => (
      <div
        style={
          params.row.aic_farmadati === "NO MATCH"
            ? { color: "red", fontSize: 14 }
            : { fontSize: 14 }
        }
      >
        {params.row.nome_commerciale_farmadati}
      </div>
    ),
  },
  {
    field: "atc",
    headerName: "ATC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "center",
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.atc}</div>
    ),
    flex: 0.5,
  },
  {
    field: "atc_farmadati",
    headerName: "ATC FARM.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "center",
    flex: 0.5,
    renderCell: (params) => (
      <div
        style={
          params.row.aic_farmadati === "NO MATCH" ||
          (params.row.atc !== "" && params.row.atc_farmadati !== params.row.atc)
            ? { color: "red", fontSize: 14 }
            : { fontSize: 14 }
        }
      >
        {params.row.atc_farmadati}
      </div>
    ),
  },
  {
    field: "atc_complementare",
    headerName: "ATC COMP.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "center",
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.atc_complementare}</div>
    ),
    flex: 0.6,
  },
  {
    field: "atc_complementare_farmadati",
    headerName: "ATC COMP. FARM.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    renderCell: (params) => (
      <div
        style={
          params.row.aic_farmadati === "NO MATCH" ||
          params.row.atc_complementare_farmadati !==
            params.row.atc_complementare
            ? { color: "red", fontSize: 14 }
            : { fontSize: 14 }
        }
      >
        {params.row.atc_complementare_farmadati}
      </div>
    ),
    align: "center",
    flex: 0.8,
  },
  {
    field: "Descrizione_ATC",
    headerName: "DESCR. ATC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.descrizione_atc}</div>
    ),
    align: "left",
    flex: 0.8,
  },
  {
    field: "Descrizione_ATC_Farmadati",
    headerName: "DESCR. ATC FARM.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    renderCell: (params) => (
      <div
        style={
          params.row.aic_farmadati === "NO MATCH"
            ? { color: "red", fontSize: 14 }
            : { fontSize: 14 }
        }
      >
        {params.row.descrizione_atc_farmadati}
      </div>
    ),
    align: "left",
    flex: 0.8,
  },
];

const SINGLE_OFF_CONFRONTO_PREZZI_COLUMNS = [
  {
    field: "aic",
    headerName: "AIC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "center",
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.aic}</div>
    ),
    flex: 0.4,
  },

  {
    field: "nome_commerciale_farmadati",
    headerName: "NOME FARMADATI",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "left",
    flex: 1,
    renderCell: (params) => (
      <div
        style={
          params.row.aic_farmadati === "NO MATCH"
            ? { color: "red", fontSize: 14 }
            : { fontSize: 14 }
        }
      >
        {params.row.nome_commerciale_farmadati}
      </div>
    ),
  },
  {
    field: "prezzo_unitario",
    headerName: "PREZZO UNITARIO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    sortable: false,
    flex: 0.6,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>
        {parseFloat(params.row?.prezzo_unitario)
          ? parseFloat(params.row?.prezzo_unitario).toFixed(5)
          : "0.00000"}{" "}
        €
      </div>
    ),
  },
  {
    field: "prezzo_confezione_calcolato",
    headerName: "PREZZO CONFEZIONE CALCOLATO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    sortable: false,
    flex: 1,
    renderCell: (params) => {
      const prezzoUnitario = parseFloat(params.row?.prezzo_unitario);
      const numUnita = parseFloat(params.row?.numero_unita_confezione);
      const prezzoPubConfezione = parseFloat(params.row?.prezzo_pub_confezione);

      // Controlli sui dati
      if (
        isNaN(prezzoUnitario) ||
        isNaN(numUnita) ||
        prezzoUnitario <= 0 ||
        numUnita <= 0
      ) {
        return <div style={{ fontSize: 16, color: "red" }}>X</div>; // Se non valido, mostra "X"
      }

      // Calcolo del prezzo confezione
      const prezzoConfezioneCalcolato = (prezzoUnitario * numUnita).toFixed(2);

      // Controllo se è diverso dal prezzo pubblicato
      const isDifferent =
        !isNaN(prezzoPubConfezione) &&
        parseFloat(prezzoConfezioneCalcolato) !== prezzoPubConfezione;

      return (
        <div
          style={{
            fontSize: 16,
            color: isDifferent ? "red" : "black",
            fontWeight: isDifferent ? "bold" : "normal",
          }}
        >
          {prezzoConfezioneCalcolato} €
          {isDifferent && (
            <span
              style={{ marginLeft: 5, color: "red", fontWeight: "bold" }}
            ></span>
          )}
        </div>
      );
    },
  },

  {
    field: "prezzo_pub_confezione",
    headerName: "PREZZO CONFEZIONE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    sortable: false,
    flex: 0.8,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>
        {parseFloat(params.row?.prezzo_pub_confezione)
          ? parseFloat(params.row?.prezzo_pub_confezione).toFixed(2)
          : "X"}{" "}
        €
      </div>
    ),
  },
  {
    field: "numero_unita_confezione",
    headerName: "NUMERO PEZZI",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    sortable: false,
    flex: 0.6,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>
        {parseFloat(params.row?.numero_unita_confezione)
          ? parseFloat(params.row?.numero_unita_confezione).toFixed(0)
          : "X"}{" "}
      </div>
    ),
  },
  {
    field: "numero_pezzi_farmadati",
    headerName: "NUMERO PEZZI FARMADATI",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    sortable: false,
    flex: 0.8,
    renderCell: (params) => {
      const numUnita = parseFloat(params.row?.numero_unita_confezione);
      const numUnitaFarma = parseFloat(params.row?.numero_pezzi_farmadati);

      // Controlli sui dati
      if (isNaN(numUnitaFarma)) {
        return <div style={{ fontSize: 16, color: "red" }}>X</div>; // Se non valido, mostra "X"
      }

      // Controllo se è diverso dal prezzo pubblicato
      const isDifferent =
        !isNaN(numUnita) && parseFloat(numUnitaFarma) !== numUnita;

      return (
        <div
          style={{
            fontSize: 16,
            color: isDifferent ? "red" : "black",
            fontWeight: isDifferent ? "bold" : "normal",
          }}
        >
          {numUnitaFarma}
          {isDifferent && (
            <span
              style={{ marginLeft: 5, color: "red", fontWeight: "bold" }}
            ></span>
          )}
        </div>
      );
    },
  },
];

const SINGLE_OFF_MAIN_COLUMNS = [
  {
    field: "descrizione_gruppo_ospedaliero",
    headerName: "TIPOLOGIA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "left",
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>
        {params.row?.descrizione_gruppo_ospedaliero}
      </div>
    ),
    flex: 1,
  },
  {
    field: "principio_attivo",
    headerName: "PRINCIPIO ATTIVO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "left",
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.principio_attivo}</div>
    ),
    flex: 1,
  },
  {
    field: "aic",
    headerName: "AIC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.aic}</div>
    ),
    flex: 0.3,
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMM.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "left",
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.nome_commerciale}</div>
    ),
    flex: 1,
  },
  {
    field: "atc_complementare",
    headerName: "ATC COMP.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.atc_complementare}</div>
    ),
    flex: 0.4,
  },
  {
    field: "prezzo_unitario",
    headerName: "PREZZO UNIT.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>
        {params.row.prezzo_unitario.toFixed(5)}
        {""}€
      </div>
    ),
  },
];

const SINGLE_OFF_MAIN_COLUMNS_CHANGE_TEST = [
  {
    field: "descrizione_gruppo_ospedaliero",
    headerName: "TIPOLOGIA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "left",
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>
        {params.row?.descrizione_gruppo_ospedaliero}
      </div>
    ),
    flex: 1,
  },
  {
    field: "principio_attivo",
    headerName: "PRINCIPIO ATTIVO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "left",
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.principio_attivo}</div>
    ),
    flex: 1,
  },
  {
    field: "aic",
    headerName: "AIC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.aic}</div>
    ),
    flex: 0.3,
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMM.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "left",
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.nome_commerciale}</div>
    ),
    flex: 1,
  },
  {
    field: "atc_complementare",
    headerName: "ATC COMP.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.atc_complementare}</div>
    ),
    flex: 0.4,
  },
  {
    field: "prezzo_unitario",
    headerName: "VECCHIO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>
        {params.row.prezzo_unitario.toFixed(5)}
        {""}€
      </div>
    ),
  },
  {
    field: "new_prize",
    headerName: "NUOVO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    flex: 0.4,
    renderCell: (params) => {
      // Controlla se 'new_prize' esiste e non è null
      if ("new_prize" in params.row) {
        // Logica esistente per visualizzare new_prize
        return (
          <div
            style={
              params.row.new_prize > params.row.prezzo_unitario
                ? { fontSize: 14, color: "red" }
                : { fontSize: 14, color: "green" }
            }
          >
            {params.row.new_prize.toFixed(5)}€
          </div>
        );
      } else {
        // Se new_prize non esiste, ritorna una stringa vuota
        return <div style={{ fontSize: 14 }}></div>;
      }
    },
    // renderCell: (params) => (
    //   <div
    //     style={
    //       params.row.new_prize > params.row.prezzo_unitario
    //         ? { fontSize: 14, color: "red" }
    //         : { fontSize: 14, color: "green" }
    //     }
    //   >
    //     {params.row?.new_prize.toFixed(5)}
    //     {""}€
    //   </div>
    // ),
  },
];

const SINGLE_OFF_VIEW_COLUMNS = [
  {
    field: "principio_attivo",
    headerName: "PRINCIPIO ATTIVO",
    headerAlign: "center",
    align: "left",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>{params.row?.principio_attivo}</div>
    ),
    flex: 0.8,
  },
  {
    field: "descrizione_gruppo_ospedaliero",
    headerName: "TIPOLOGIA",
    headerAlign: "center",
    align: "left",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>
        {params.row?.descrizione_gruppo_ospedaliero}
      </div>
    ),
    flex: 1,
  },
  {
    field: "aic",
    headerName: "AIC",
    align: "center",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>{params.row?.aic}</div>
    ),
    flex: 0.3,
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerAlign: "center",
    align: "left",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>{params.row?.nome_commerciale}</div>
    ),
    flex: 1,
  },

  {
    field: "ragione_sociale",
    headerName: "FORNITORE",
    headerAlign: "center",
    align: "left",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>{params.row?.ragione_sociale}</div>
    ),
    flex: 1,
  },
  {
    field: "prezzo_unitario",
    headerName: "PREZZO UNIT.",
    align: "right",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>
        {params.row.prezzo_unitario.toFixed(5)}
        {""}€
      </div>
    ),
  },
];

export {
  SINGLE_OFF_ERRORS_COLUMNS,
  SINGLE_OFF_IMPORT_COLUMNS,
  SINGLE_OFF_CONFRONTO_COLUMNS,
  SINGLE_OFF_CONFRONTO_PREZZI_COLUMNS,
  SINGLE_OFF_MAIN_COLUMNS,
  SINGLE_OFF_MAIN_COLUMNS_CHANGE_TEST,
  SINGLE_OFF_VIEW_COLUMNS,
};
