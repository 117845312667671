import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OFFER_SUPPLIERS_CHECK_POPUP = ({
  checkSuppliersOpen,
  setCheckSuppliersOpen,
  suppResumeList,
  visualizzaDataOra,
}) => {
  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <Dialog
          open={checkSuppliersOpen}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth="lg"
          keepMounted
          onClose={() => setCheckSuppliersOpen(false)}
          PaperProps={{
            style: {
              backgroundColor: "#f4f4f4",
              border: "1px solid #ccc",
              borderRadius: "10px",
              textAlign: "center",
              height: "500px",
            },
          }}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle
            sx={{
              fontFamily: "Calibri",
              fontWeight: "bold",
              color: "#1976d2",
              fontSize: 20,
            }}
          >
            FORNITORI INSERITI:
          </DialogTitle>
          <DialogContent
            sx={{
              fontFamily: "Calibri",
              color: "#1976d2",
              fontSize: 25,
              textAlign: "left",
            }}
          >
            <div>
              {suppResumeList?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "1%",
                  }}
                >
                  <b>{item?.ragione_sociale}</b>
                  <br />
                  Ultima modifica: {visualizzaDataOra(item?.data_modifica)}
                </div>
              ))}
            </div>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default OFFER_SUPPLIERS_CHECK_POPUP;
