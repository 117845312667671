import React, { useState, useEffect } from "react";
import "../../../App.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

// import PDF_ESEMPIO from "../../../utils/PDF_TEMP/PDF OFFERTA TEST.pdf";

import EXP_ATC_ORDERED from "../../../exports/EXP_LISTINO/EXP_ATC_ORDERED";
import EXP_PRINCIPIO_ATTIVO_ORDERED from "../../../exports/EXP_LISTINO/EXP_PRINCIPIO_ATTIVO_ORDERED";
import EXP_GRUPPO_OSP_MIN from "../../../exports/EXP_LISTINO/EXP_GRUPPO_OSP_MIN";
import EXP_CONFRONTO_LISTINI from "../../../exports/EXP_LISTINO/EXP_CONFRONTO_LISTINI";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import BrowserUpdatedOutlinedIcon from "@mui/icons-material/BrowserUpdatedOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import { OFF_RESUME_VIEW_COLUMNS } from "../../../colums/OFF_LIST_COLUMNS";

import BeatLoader from "react-spinners/BeatLoader";
import offersResumeService from "../../../services/offers_resume";

import offlist_search_options from "../../../utils/SEARCH_OPTIONS/offlist_search_options.json";

import uploadpdf from "../../../services/uploadpdf";

import OFFERSLIST_SEARCH_POPUP from "../../../popup/SEARCH_POPUP/OFFERSLIST_SEARCH_POPUP";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  palette: {
    bluetest: {
      main: "#1976d2",
      light: "#328ce7",
      dark: "#1566b7",
      contrastText: "#fff",
    },
    redtest: {
      main: "#ff0000",
      light: "#ff3333",
      dark: "#cc0000",
      contrastText: "#fff",
    },
  },
});

const OffersListView = ({ user, setChangePageFlag }) => {
  const [offerList, setOfferList] = useState([]);
  const [Off_List_Selected, setOff_List_Selected] = useState(null);
  const [Off_List_Downloaded, setOff_List_Downloaded] = useState(null);

  const [Off_List_TEMP, setOff_List_TEMP] = useState(null);

  const [Off_List_Selected_BIS, setOff_List_Selected_BIS] = useState(null);

  const [Off_List_Conf_FLAG, setOff_List_Conf_FLAG] = useState(false);

  const [popUpExportOpen, setPopUpExportOpen] = useState(false);

  const [loadingOfferList, setLoadingOfferList] = useState(true);
  const [loadingSingleOfferList, setLoadingSingleOfferList] = useState(false);

  const [searchValue, setSearchValue] = useState("");

  const [filteredResults, setFilteredResults] = useState(null);

  const [searchedFlag, setSearchedFlag] = useState(false);
  const [searchPopupOpen, setSearchPopupOpen] = useState(false);
  const [notFoundFlag, setNotFoundFlag] = useState(false);

  const [selectedField, setSelectedField] = useState(
    offlist_search_options.find((option) => option.name === "NOME COMMERCIALE")
  );
  const [fieldSuggestions, setFieldSuggestions] = useState([]);

  useEffect(() => {
    offersResumeService.getOfferaResumeByUserId(user.id).then((res) => {
      setOfferList(res.data);
      setLoadingOfferList(false);
    });
  }, []);

  const createOffersResumeView = async (offersResumeTEMP) => {
    let finalOBJ = [];
    let id_count = 0;

    // console.log("offersResumeTEMP", offersResumeTEMP);
    await offersResumeTEMP.offerte_inserite.map((OFF_SEL) => {
      OFF_SEL.offerta_obj.map((SINGLE_OFF_SEL) => {
        let temp = {
          id_temp: id_count,

          ragione_sociale: OFF_SEL.ragione_sociale,
          cf_piva: OFF_SEL.cf_piva,
          data_inserimento: OFF_SEL.data_inserimento,
          ordine_min: OFF_SEL.ordine_min,
          pdf_offerta_id: OFF_SEL?.pdf_offerta_id ?? null,

          aic: SINGLE_OFF_SEL.aic,
          atc: SINGLE_OFF_SEL.atc,
          atc_complementare: SINGLE_OFF_SEL.atc_complementare,
          codice_gruppo_ospedaliero: SINGLE_OFF_SEL.codice_gruppo_ospedaliero,
          data_protocollo: SINGLE_OFF_SEL.data_protocollo,
          date: SINGLE_OFF_SEL.date,
          descrizione_atc: SINGLE_OFF_SEL.descrizione_atc,
          descrizione_gruppo_ospedaliero:
            SINGLE_OFF_SEL.descrizione_gruppo_ospedaliero,
          dosaggio: SINGLE_OFF_SEL.dosaggio,
          fascia: SINGLE_OFF_SEL.fascia,
          filef_flag: SINGLE_OFF_SEL.filef_flag,
          forma_farmaceutica: SINGLE_OFF_SEL.forma_farmaceutica,
          is_checked: SINGLE_OFF_SEL.is_checked,
          iva: SINGLE_OFF_SEL.iva,
          nome_commerciale: SINGLE_OFF_SEL.nome_commerciale,
          numero_protocollo: SINGLE_OFF_SEL.numero_protocollo,
          numero_unita_confezione: SINGLE_OFF_SEL.numero_unita_confezione,
          prezzo_netto_exfactory_confezione:
            SINGLE_OFF_SEL.prezzo_netto_exfactory_confezione,
          prezzo_netto_exfactory_unitario:
            SINGLE_OFF_SEL.prezzo_netto_exfactory_unitario,
          prezzo_pub_confezione: SINGLE_OFF_SEL.prezzo_pub_confezione,
          prezzo_pub_unitario: SINGLE_OFF_SEL.prezzo_pub_unitario,
          prezzo_unitario: SINGLE_OFF_SEL.prezzo_unitario,
          principio_attivo: SINGLE_OFF_SEL.principio_attivo,
          sconto_precentuale_prezzo_pub:
            SINGLE_OFF_SEL.sconto_precentuale_prezzo_pub,
        };

        id_count = id_count + 1;

        finalOBJ.push(temp);
        return OFF_SEL.offerta_obj;
      });

      return offersResumeTEMP.offerte_inserite;
    });

    return finalOBJ;
  };

  const ordinaPerPrincipioAttivo = async (data) => {
    // Step 1: Filter out items where 'principio_attivo' is empty or "NESSUN PRINCIPIO ATTIVO"
    const filteredItems = data.filter(
      (item) =>
        !item.principio_attivo ||
        item.principio_attivo === "NESSUN PRINCIPIO ATTIVO"
    );

    // Step 2: Filter out items where 'principio_attivo' is neither empty nor "NESSUN PRINCIPIO ATTIVO"
    const remainingItems = data.filter(
      (item) =>
        item.principio_attivo &&
        item.principio_attivo !== "NESSUN PRINCIPIO ATTIVO"
    );

    // Step 3: Sort the remaining items by 'descrizione_gruppo_ospedaliero' and then by 'prezzo_unitario'
    remainingItems.sort((a, b) => {
      // If 'descrizione_gruppo_ospedaliero' is empty, sort it to the end
      if (!a.descrizione_gruppo_ospedaliero) return 1;
      if (!b.descrizione_gruppo_ospedaliero) return -1;

      if (a.descrizione_gruppo_ospedaliero < b.descrizione_gruppo_ospedaliero)
        return -1;
      if (a.descrizione_gruppo_ospedaliero > b.descrizione_gruppo_ospedaliero)
        return 1;
      return a.prezzo_unitario - b.prezzo_unitario;
    });

    // Step 4: Combine the sorted remaining items with the filtered items
    const finalItems = [...remainingItems, ...filteredItems];

    // Step 5: Return the combined result
    return finalItems;
  };

  const handleOfferListDownload = async () => {
    setLoadingSingleOfferList(true);
    await offersResumeService
      .getOffersResumetByOffersResumetId(Off_List_Selected.id)
      .then(async (res) => {
        let OFFERS_TEMP = await createOffersResumeView(res.data);

        let OFFERS_TEMP_ORDERED = await ordinaPerPrincipioAttivo(OFFERS_TEMP);

        let SUGG_TEMP = [];

        await OFFERS_TEMP_ORDERED.map((Selected) => {
          SUGG_TEMP.push(Selected.nome_commerciale);

          return OFFERS_TEMP_ORDERED;
        });

        const SUGG_TEMP_UNIQUE = [...new Set(SUGG_TEMP)];
        SUGG_TEMP_UNIQUE.sort();
        setFieldSuggestions(SUGG_TEMP_UNIQUE);

        setOff_List_Downloaded(res.data);
        setOff_List_TEMP(OFFERS_TEMP_ORDERED);
        setLoadingSingleOfferList(false);
      });
  };

  const handleResetOfferListDownload = () => {
    setOff_List_Downloaded(null);
    setOff_List_Selected(null);
  };

  //!-------

  const handleResetSearch = () => {
    setFilteredResults(null);
    setSearchedFlag(false);
    setNotFoundFlag(false);
    setSearchPopupOpen(false);
  };

  const handleSearch = () => {
    if (searchValue === null) {
      handleResetSearch();
    } else {
      let ArrayToSearch = [...Off_List_TEMP];
      const filtered = ArrayToSearch.filter((item) =>
        item[selectedField.field]
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      );

      if (filtered.length > 0) {
        setFilteredResults(filtered);
        setNotFoundFlag(false);
        setSearchPopupOpen(false);

        setSearchedFlag(true);
      } else {
        setFilteredResults(filtered);
        setNotFoundFlag(true);
      }
    }
  };

  const createSuggestionsList = async (newValue, offersListData) => {
    let arrayToSearch = [...offersListData];

    let SUGG_TEMP = [];

    await arrayToSearch.map((Selected) => {
      SUGG_TEMP.push(Selected[newValue.field]);

      return arrayToSearch;
    });

    const SUGG_TEMP_UNIQUE = [...new Set(SUGG_TEMP)];
    SUGG_TEMP_UNIQUE.sort();

    return SUGG_TEMP_UNIQUE;
  };

  const handleFieldChange = async (e, newValue) => {
    if (newValue !== null) {
      setSelectedField(newValue);

      let SUGGESTIONS_LIST_TEMP = await createSuggestionsList(
        newValue,
        Off_List_TEMP
      );

      setFieldSuggestions(SUGGESTIONS_LIST_TEMP);
    }
  };

  const downloadPdf = async (fileId) => {
    try {
      console.log(`Downloading file with ID: ${fileId}`);
      const blob = await uploadpdf.downloadFile(fileId);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "PDF_OFFERTA.pdf"; // Nome del file che sarà scaricato
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("There was an error downloading the PDF:", error);
    }
  };

  // Funzione per gestire il click sul pulsante di download del PDF
  const handlePDF = (params) => {
    const fileId = params?.row.pdf_offerta_id; // Assicurati che questa sia la proprietà corretta
    console.log(`Handle PDF for file ID: ${fileId}`);

    if (fileId) {
      downloadPdf(fileId);
    } else {
      console.log("PDF ASSENTE");
      alert("PDF ASSENTE");
    }
  };

  return (
    <>
      <div className="allOfferPage">
        <ThemeProvider theme={theme}>
          {loadingOfferList ? (
            <div style={{ paddingTop: "8%" }}>
              <BeatLoader
                color={"#1976d2"}
                loading={loadingOfferList}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <>
              {Off_List_Downloaded === null && (
                <>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={offerList}
                    getOptionLabel={(option) => option.nome_listino}
                    sx={{
                      width: "100%",
                      paddingTop: "10%",
                      paddingLeft: "38%",
                      paddingRight: "38%",
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="LISTINI" />
                    )}
                    value={Off_List_Selected}
                    onChange={(e, newValue) => {
                      setOff_List_Selected(newValue);
                    }}
                  />
                  {Off_List_Selected !== null && (
                    <div
                      style={{
                        paddingTop: "2%",
                        paddingLeft: "35%",
                        paddingRight: "35%",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="bluetest"
                        startIcon={<BrowserUpdatedOutlinedIcon />}
                        onClick={handleOfferListDownload}
                      >
                        CARICA SELEZIONATO
                      </Button>
                    </div>
                  )}
                </>
              )}
              {loadingSingleOfferList && (
                <div style={{ paddingTop: "2%" }}>
                  <BeatLoader
                    color={"#1976d2"}
                    loading={loadingSingleOfferList}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              )}
            </>
          )}
          {Off_List_Downloaded !== null && (
            <>
              <div
                style={{
                  display: "inline-block",
                  width: "15%",
                  textAlign: "left",
                }}
              >
                <Button
                  variant="contained"
                  color="redtest"
                  startIcon={<ChevronLeftOutlinedIcon />}
                  onClick={handleResetOfferListDownload}
                >
                  INDIETRO
                </Button>
              </div>
              <div
                style={{
                  display: "inline-block",
                  width: "15%",
                  textAlign: "left",
                }}
              >
                <Button
                  variant="contained"
                  color="bluetest"
                  startIcon={<SearchIcon />}
                  onClick={() => setSearchPopupOpen(true)}
                >
                  CERCA
                </Button>
              </div>
              {!searchedFlag ? (
                <div
                  style={{
                    display: "inline-block",
                    width: "35%",
                    textAlign: "left",
                  }}
                ></div>
              ) : (
                <div
                  style={{
                    display: "inline-block",
                    width: "35%",
                    textAlign: "left",
                  }}
                >
                  <Button
                    variant="contained"
                    color="redtest"
                    startIcon={<RestartAltIcon />}
                    onClick={handleResetSearch}
                  >
                    RESET CERCA
                  </Button>
                </div>
              )}

              <div
                style={{
                  display: "inline-block",
                  width: "20%",
                  textAlign: "right",
                }}
              >
                <Button
                  variant="contained"
                  color="bluetest"
                  startIcon={<FileDownloadOutlinedIcon />}
                  onClick={() => setPopUpExportOpen(true)}
                >
                  ESPORTA
                </Button>
              </div>
              <div
                style={{
                  display: "inline-block",
                  width: "15%",
                  textAlign: "right",
                }}
              ></div>
              <Box
                sx={{
                  height: "430px",
                  width: "100%",
                  paddingLeft: "2%",
                  paddingRight: "2%",
                  paddingTop: "3%",
                  "& .theme_header": {
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    fontFamily: "Calibri",
                    fontSize: "16px",
                  },
                  "& .theme_cell": {
                    fontFamily: "Calibri",
                    fontSize: "17px",
                    border: "1px solid",
                  },
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    width: "25%",
                    textAlign: "left",
                  }}
                >
                  <p
                    style={{
                      textAlign: "left",
                      color: "#1976d2",
                      fontFamily: "Calibri",
                      fontSize: "25px",
                      fontWeight: "bold",
                    }}
                  >
                    {Off_List_Downloaded.nome_listino}
                  </p>
                </div>
                <div
                  style={{
                    display: "inline-block",
                    width: "50%",
                  }}
                ></div>

                <div
                  style={{
                    display: "inline-block",
                    width: "25%",
                    textAlign: "right",
                  }}
                >
                  <p
                    style={{
                      color: "#1976d2",
                      fontFamily: "Calibri",
                      fontSize: "17px",
                      fontWeight: "bold",
                    }}
                  >
                    TOT Elementi: {Off_List_TEMP.length}
                  </p>
                </div>

                {!searchedFlag ? (
                  <DataGridPro
                    columns={OFF_RESUME_VIEW_COLUMNS}
                    rows={Off_List_TEMP}
                    getRowId={(row) => row.id_temp}
                    headerHeight={40}
                    rowHeight={33}
                    onRowDoubleClick={handlePDF}
                    disableColumnMenu
                    hideFooter
                  />
                ) : (
                  <DataGridPro
                    columns={OFF_RESUME_VIEW_COLUMNS}
                    rows={filteredResults}
                    getRowId={(row) => row.id_temp}
                    headerHeight={40}
                    rowHeight={33}
                    onRowDoubleClick={handlePDF}
                    disableColumnMenu
                    hideFooter
                  />
                )}
              </Box>
              <OFFERSLIST_SEARCH_POPUP
                searchPopupOpen={searchPopupOpen}
                setSearchPopupOpen={setSearchPopupOpen}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                offlist_search_options={offlist_search_options}
                handleSearch={handleSearch}
                notFoundFlag={notFoundFlag}
                selectedField={selectedField}
                fieldSuggestions={fieldSuggestions}
                handleFieldChange={handleFieldChange}
                searchedFlag={searchedFlag}
                handleResetSearch={handleResetSearch}
              />
              <Dialog
                open={popUpExportOpen}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth="sm"
                keepMounted
                onClose={() => {
                  setPopUpExportOpen(false);
                  setOff_List_Conf_FLAG(false);
                }}
                PaperProps={{
                  style: {
                    backgroundColor: "#cccccc",
                    minHeight: "500px", // Imposta l'altezza minima
                  },
                }}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle
                  sx={{
                    fontFamily: "Calibri",
                    fontWeight: "bold",
                    fontSize: 25,
                    textAlign: "center",
                  }}
                ></DialogTitle>
                <DialogContent>
                  <p
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: "1%",
                      }}
                    >
                      <EXP_PRINCIPIO_ATTIVO_ORDERED
                        data={Off_List_TEMP}
                        nome_listino={Off_List_Downloaded.nome_listino}
                      />
                    </div>
                    <div
                      style={{
                        marginBottom: "1%",
                      }}
                    >
                      <EXP_ATC_ORDERED
                        data={Off_List_TEMP}
                        nome_listino={Off_List_Downloaded.nome_listino}
                      />
                    </div>

                    <div>
                      <b>-----</b>
                    </div>
                    <div
                      style={{
                        marginBottom: "1%",
                      }}
                    >
                      <EXP_GRUPPO_OSP_MIN
                        data={Off_List_TEMP}
                        nome_listino={Off_List_Downloaded.nome_listino}
                      />
                    </div>
                    <div
                      style={{
                        marginBottom: "1%",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="bluetest"
                        startIcon={<FileDownloadOutlinedIcon />}
                        onClick={() => setOff_List_Conf_FLAG(true)}
                      >
                        EXP CONFRONTO CON LISTINO PRECEDENTE
                      </Button>
                    </div>
                    {Off_List_Conf_FLAG && (
                      <>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={offerList}
                          getOptionLabel={(option) => option.nome_listino}
                          sx={{
                            width: "100%",
                            marginTop: "2%",
                            paddingLeft: "23%",
                            paddingRight: "23%",
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="LISTINI" />
                          )}
                          value={Off_List_Selected_BIS}
                          onChange={(e, newValue) => {
                            setOff_List_Selected_BIS(newValue);
                          }}
                        />
                        {Off_List_Selected_BIS !== null && (
                          <div
                            style={{
                              paddingTop: "2%",
                              paddingLeft: "35%",
                              paddingRight: "35%",
                            }}
                          >
                            <EXP_CONFRONTO_LISTINI
                              data={Off_List_TEMP}
                              listino={Off_List_Selected_BIS}
                              year_main_list={Off_List_Downloaded.anno}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </p>
                </DialogContent>
              </Dialog>
            </>
          )}
        </ThemeProvider>
      </div>
    </>
  );
};

export default OffersListView;
