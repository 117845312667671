import * as XLSX from "xlsx";

const acceptableFileName = ["xlsx", "xls"];

const createErrorObject = (item, errorFlags) => {
  return {
    id_temp: item.id_temp,
    aic: item.aic,
    nome_commerciale: item.nome_commerciale,
    fornitore_ragioneSociale_FILE: item.fornitore_ragioneSociale_FILE,
    fornitore_pivaFILE: item.fornitore_pivaFILE,
    atc: item.atc,
    atc_complementare: item.atc_complementare,
    prezzo_unitario: item.prezzo_unitario,
    quantita: item.quantita,
    struttura: item.struttura,
    ...errorFlags,
  };
};

const findCommonElementsWithFlags = (data) => {
  // Utilizziamo reduce per costruire una mappa con l'id_temp come chiave
  const idMap = data.reduce((acc, item) => {
    if (!acc[item.id_temp]) {
      // Creiamo un nuovo oggetto per questo id_temp
      acc[item.id_temp] = { ...item };
    } else {
      // Combiniamo i flag di errore, impostandoli a true se uno degli oggetti ha il flag impostato su true
      Object.keys(item).forEach((key) => {
        if (typeof item[key] === "boolean" && key.startsWith("flag_")) {
          acc[item.id_temp][key] = acc[item.id_temp][key] || item[key];
        }
      });
    }
    return acc;
  }, {});

  // Convertiamo la mappa in un array di oggetti
  return Object.values(idMap);
};

const handleUploadDataControl = async (
  fileInserted,
  setDataFile,
  setImportErrors,
  setLoadingPercentage
) => {
  let newFileData = [];
  let id_data_temp = 0;

  fileInserted.forEach((Sel, index) => {
    const temp = {
      id_temp: id_data_temp++,
      aic: Sel.AIC || "",
      nome_commerciale: Sel["NOME COMMERCIALE"] || "",
      fornitore_ragioneSociale_FILE: Sel.FORNITORE || "",
      fornitore_pivaFILE: Sel["P. IVA"] ? Sel["P. IVA"].toString() : "",
      atc: Sel.ATC || "",
      atc_complementare: Sel["ATC COMPLEMENTARE"] || "",
      prezzo_unitario: Sel["PREZZO"] || "",
      quantita: Sel["QUANTITA"] || "",
      struttura: Sel["STRUTTURA"] || "",
    };
    newFileData.push(temp);
    setLoadingPercentage(((index + 1) / fileInserted.length) * 50); // Aggiorna la percentuale di caricamento
  });

  const errors = {
    aic_error: [],
    name_error: [],
    piva_error: [],
    prezzo_error: [],
    quantita_error: [],
    struttura_error: [],
    flag_error: false,
  };

  // Validazione dei dati e creazione degli errori
  newFileData.forEach((Sel) => {
    if (!Sel.aic || Sel.aic.toString().trim().length > 9) {
      errors.aic_error.push(Sel);
      errors.flag_error = true;
    }
    if (!Sel.nome_commerciale) {
      errors.name_error.push(Sel);
      errors.flag_error = true;
    }
    if (!Sel.fornitore_pivaFILE) {
      errors.piva_error.push(Sel);
      errors.flag_error = true;
    }
    // if (!Sel.prezzo_unitario) {
    //   errors.prezzo_error.push(Sel);
    //   errors.flag_error = true;
    // }
    if (!Sel.quantita) {
      errors.quantita_error.push(Sel);
      errors.flag_error = true;
    }
    if (!Sel.struttura) {
      errors.struttura_error.push(Sel);
      errors.flag_error = true;
    }
  });

  if (!errors.flag_error) {
    // Format AIC and other fields
    newFileData = newFileData.map((Sel) => {
      // Convert `Sel.aic` to string if it exists, then use `padStart`
      Sel.aic = Sel.aic ? Sel.aic.toString().padStart(9, "0") : "";

      if (Sel.atc) Sel.atc = Sel.atc.toString();
      if (Sel.atc_complementare)
        Sel.atc_complementare = Sel.atc_complementare.toString();

      return Sel;
    });

    setDataFile(newFileData);
    setImportErrors(null);
  } else {
    let newErrorsTEMP = [];

    // Collect all error types into `newErrorsTEMP`
    Object.keys(errors).forEach((key) => {
      if (key.endsWith("_error") && Array.isArray(errors[key])) {
        errors[key].forEach((ERR_SEL) => {
          const errorFlags = {
            flag_aic_error: key === "aic_error",
            flag_name_error: key === "name_error",
            flag_piva_error: key === "piva_error",
            flag_prezzo_error: key === "prezzo_error",
            flag_quantita_error: key === "quantita_error",
            flag_struttura_error: key === "struttura_error",
          };
          newErrorsTEMP.push(createErrorObject(ERR_SEL, errorFlags));
        });
      }
    });

    const commonElements = findCommonElementsWithFlags(newErrorsTEMP);

    setImportErrors(commonElements);
  }
};

const checkFileName = (name) =>
  acceptableFileName.includes(name.split(".").pop().toLowerCase());

const readDataFromFile = (data) =>
  XLSX.utils.sheet_to_json(
    XLSX.read(data).Sheets[XLSX.read(data).SheetNames[0]]
  );

export const handleFile = async (
  e,
  setDataFile,
  setImportErrors,
  setLoadingPercentage
) => {
  setDataFile(null);
  const myfile = e.target.files[0];
  if (!myfile) return;

  if (!checkFileName(myfile.name)) {
    alert("Invalid file type!");
    return;
  }

  const data = await myfile.arrayBuffer();
  const mySheetData = readDataFromFile(data);
  handleUploadDataControl(
    mySheetData,
    setDataFile,
    setImportErrors,
    setLoadingPercentage
  );
};
