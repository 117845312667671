import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import BeatLoader from "react-spinners/BeatLoader";

import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OFFER_CREATE_OFFERSRESUME_POPUP = ({
  createOffersResumeOpen,
  fileSavedName,
  setFileSavedName,
  errors,
  handleSaveOfferResume,
  loadingSaveOffersResume,
  salvaOffersresume_ERR,
  handleNotCreateResume,
  supplierName,
}) => {
  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <Dialog
          open={createOffersResumeOpen}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth="lg"
          keepMounted
          onClose={handleNotCreateResume}
          PaperProps={{
            style: {
              backgroundColor: "#f4f4f4",
              border: "1px solid #ccc",
              borderRadius: "10px",
              textAlign: "center",
              height: "250px",
            },
          }}
          aria-describedby="alert-dialog-slide-description"
        >
          {/* <DialogTitle
            sx={{
              fontFamily: "Calibri",
              fontWeight: "bold",
              color: "#009900",
              fontSize: 35,
            }}
          >
            <span style={{ fontSize: "28px" }}>OFFERTA:</span> {supplierName}{" "}
            <br /> SALVATA CORRETTAMENTE!
          </DialogTitle> */}
          <DialogTitle
            sx={{
              fontFamily: "Calibri",
              fontWeight: "bold",
              color: "#1976d2",
              fontSize: 20,
            }}
          >
            VUOI SALVARE UN NUOVO LISTINO?
          </DialogTitle>
          <DialogContent>
            <div>
              <TextField
                id="file_name"
                label="NOME DEL LISTINO"
                placeholder="Inserisci il Nome del Listino"
                sx={{ width: 300 }}
                value={fileSavedName}
                onChange={(e) => setFileSavedName(e.target.value)}
                error={errors.fileSavedName ? true : false}
                helperText={errors.fileSavedName}
              />
            </div>
            <div>
              <div
                style={{
                  marginTop: "2%",
                  textAlign: "left",
                  display: "inline-block",
                  width: "42.5%",
                }}
              >
                <Button
                  variant="contained"
                  color="bluetest"
                  component="label"
                  startIcon={<ArrowBackIosNewOutlinedIcon />}
                  onClick={handleNotCreateResume}
                >
                  INSERISCI NUOVA OFFERTA
                </Button>
              </div>

              <div
                style={{
                  marginTop: "2%",
                  textAlign: "left",
                  display: "inline-block",
                  width: "57.5%",
                }}
              >
                <Button
                  variant="contained"
                  color="success"
                  component="label"
                  startIcon={<SaveOutlinedIcon />}
                  onClick={() => handleSaveOfferResume()}
                >
                  SALVA LISTINO
                </Button>
              </div>
            </div>
            {loadingSaveOffersResume && (
              <div style={{ textAlign: "center", paddingTop: "3%" }}>
                <BeatLoader
                  color={"#1976d2"}
                  loading={loadingSaveOffersResume}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
                <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                  CARICAMENTO...
                </h6>
              </div>
            )}
            {salvaOffersresume_ERR && (
              <>
                <div
                  style={{
                    fontFamily: "Calibri",
                    fontSize: 27,
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  ERRORE NEL SALVATAGGIO
                </div>
              </>
            )}
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default OFFER_CREATE_OFFERSRESUME_POPUP;
